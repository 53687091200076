import { NavTreeItem } from '@/lib/getters/getNav';
import { parseLinkField } from '@/lib/parsers/linkField';
import React from 'react';
import { Button, NavButton } from '../Atoms/Button';
import { DownChevron, PhoneIcon, RightArrow, SearchIcon } from '../Atoms/Icons';
import ExpandingBlock from '../utility/ExpandingBlock';
import Link from 'next/link';
import { normalizeLinkHref } from '@/lib/parsers/normalizeLinkHref';

interface MobileNavMenuProps {
  open: boolean;
  phoneLink: ReturnType<typeof parseLinkField>;
  utilitiesNav: NavTreeItem[];
  primaryNav: NavTreeItem[];
  header: React.ReactNode;
}

const MobileNavMenuItem = ({ item }: { item: NavTreeItem }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <li>
      {item.children.length > 0 ? (
        <>
          <button
            onClick={() => setOpen(!open)}
            className="flex w-full items-center justify-between">
            <h4>{item.title}</h4>
            <DownChevron
              className={`ml-2 stroke-green-600 text-lg ${open ? 'rotate-180 transform' : ''}`}
            />
          </button>
          <ExpandingBlock expanded={open}>
            <ul className="space-y-3 pt-3 font-medium">
              <li>
                <Link href={normalizeLinkHref(item.nodeUri) || '#'}>
                  <h6 className="inline-flex items-center text-m">
                    {item.title}&ensp;
                    <RightArrow className="stroke-green-600" />
                  </h6>
                </Link>
              </li>
              {item.children.map((child) => (
                <li key={child.id}>
                  <Link href={normalizeLinkHref(child.nodeUri) || '#'}>{child.title}</Link>
                </li>
              ))}
            </ul>
          </ExpandingBlock>
        </>
      ) : (
        <Link href={normalizeLinkHref(item.nodeUri) || '#'}>
          <h4>{item.title}</h4>
        </Link>
      )}
    </li>
  );
};

const MobileNavMenu = ({
  open,
  phoneLink,
  utilitiesNav,
  primaryNav,
  header,
}: MobileNavMenuProps) => {
  return (
    <div
      className={`lg:hidden ${
        open ? 'block' : 'hidden'
      } fixed top-0 z-50 h-full w-full overflow-auto bg-sky-200`}>
      <div className="px-4 py-8">{header}</div>
      <ul className="flex flex-col gap-6 px-4 pb-16 text-green-600">
        {primaryNav.map((item) => (
          <MobileNavMenuItem key={item.id} item={item} />
        ))}
        <li>
          <Button color={'greenOutline'} href="/search" element="a">
            Search&ensp;
            <SearchIcon className="fill-white" />
          </Button>
        </li>
        <li>
          <Button color={'darkGreen'} href="/get-involved/donate/">
            Donate
          </Button>
        </li>
        {utilitiesNav.map((item) => (
          <li key={item.id}>
            <NavButton color={'blue'} href={normalizeLinkHref(item.nodeUri)}>
              {item.title}
            </NavButton>
          </li>
        ))}
        <li>
          <NavButton color={'blue'} href={'tel:' + phoneLink?.href}>
            <PhoneIcon className={`mr-2 ${'fill-green-600'}`} />
            {phoneLink?.children}
          </NavButton>
        </li>
      </ul>
    </div>
  );
};
export default MobileNavMenu;
