import React, { useRef, useEffect, useState } from 'react';

interface ExpandingBlockProps {
  startHeight?: number;
  expanded: boolean;
  children: React.ReactNode;
  className?: string;
  extraHeight?: number;
  duration?: string;
}

const ExpandingBlock: React.FC<ExpandingBlockProps> = ({
  startHeight = 0,
  expanded,
  children,
  className,
  extraHeight = 0,
  duration = 'duration-300',
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>(startHeight + 'px');

  useEffect(() => {
    const getHeight = (): string => {
      if (contentRef?.current)
        return expanded
          ? `${contentRef?.current?.scrollHeight + extraHeight}px`
          : startHeight + 'px';
      return startHeight + 'px';
    };

    setHeight(getHeight());

    const handleResize = (): void => {
      setHeight(getHeight());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [expanded, extraHeight, startHeight]);

  return (
    <div
      className={`transition-height overflow-hidden ${duration} ease-in-out ${className}`}
      style={{ height }}>
      <div ref={contentRef}>{children}</div>
    </div>
  );
};

export default ExpandingBlock;
