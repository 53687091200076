import Link from 'next/link';
import { RightArrow } from '../Icons';
import { VariantProps, cva } from 'class-variance-authority';

type LinkArrowProps = VariantProps<typeof LinkArrowStyles> & {
  linkHref: string;
  onClick?: () => void;
  children: React.ReactNode;
};

const LinkArrowStyles = cva('flex items-center font-medium', {
  variants: {
    cover: {
      true: 'before:absolute before:inset-0',
      false: 'group',
    },
    color: {
      green: 'text-green-600 stroke-green-600',
      lightBlue: 'text-sky-300 stroke-sky-300',
    },
  },
  defaultVariants: {
    cover: false,
    color: 'green',
  },
});

export const LinkArrow = ({
  linkHref,
  children,
  cover = false,
  color,
  onClick,
}: LinkArrowProps) => {
  return (
    <Link onClick={onClick} href={linkHref} className={LinkArrowStyles({ cover, color })}>
      {children}
      <RightArrow className="ml-2 transition-transform group-hover:translate-x-[2px]" />
    </Link>
  );
};
