import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.cjs");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@liquorice/next-apollo/src/ApolloWrapper/ApolloWrapperInternal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/Atoms/Button/ScrollToTopButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchIcon"] */ "/vercel/path0/frontend/src/components/Atoms/Icons/SearchIcon.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/HeaderNavBar/HeaderNavBar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/public/DCR_logo.png");
